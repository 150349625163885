<template>
  <div class="orange-filled-btn-container">
    <button @click="click">{{ btnText }}</button>
  </div>
</template>

<script>
export default {
  props: {
    btnText: {
      type: String,
      default: () => "",
    },
    click: {
      type: Function,
      default: () => Function,
    },
  },
};
</script>

<style lang="scss">
.orange-filled-btn-container {
  border-radius: 14px;
  background: #cd935a;
  > button {
    padding: 20px;
    width: 100%;
    background: none;
    border: none;
    text-align: center;
    font: {
      weight: 600;
      size: 17px;
    }
  }
}
</style>
