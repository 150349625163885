import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Styles
import "@/styles/general.scss";
import "vue-select/dist/vue-select.css";

// Components
import VueSelect from "vue-select";

VueSelect.props.components.default = () => ({
  OpenIndicator: {
    render: () => h("img", { src: require("./assets/images/chevron.png") }),
  },
});

createApp(App)
  .use(store)
  .use(router)
  .component("v-select", VueSelect)
  .mount("#app");
