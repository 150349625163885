<template>
  <div class="default-input-container">
    <label>{{ label }}</label>
    <input
      class="default-input"
      :placeholder="placeholder"
      :type="type"
      :name="name"
      @input="(e) => $emit('input-vue', e.target.value)"
      :value="value"
    />
    <slot name="addition" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => "",
    },
    name: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "text",
    },
    value: {
      type: String || Number,
      default: () => "",
    },
  },
};
</script>

<style lang="scss">
.default-input-container {
  position: relative;
  > label {
    display: block;
    text-transform: capitalize;
    margin-bottom: 4px;
    font: {
      size: 13px;
      weight: 400;
    }
  }
  > .default-input {
    padding: 12px;
    border-radius: 14px;
    border: 1px solid #d1d1d6;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    width: 100%;
    &::placeholder {
      color: #d1d1d6;
    }
  }
}
</style>
