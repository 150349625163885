<template>
  <div class="layout">
    <router-view />
  </div>
</template>

<style lang="scss">
.layout {
  background-color: #dadde0;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
}
</style>
