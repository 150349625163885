import { createRouter, createWebHashHistory } from "vue-router";
import LoginLayout from "../pages/LoginLayout.vue";
import Login from "../pages/login/LoginVue.vue";

const NewPass = () => import("../pages/login/NewPass.vue");
const ConfirmPassword = () => import("../pages/login/ConfirmPass.vue");
const HeaderLayout = () => import("../pages/HeaderLayout.vue");
const MainPage = () => import("../pages/MainPage.vue");
const RolesPage = () => import("../pages/RolesPage.vue");
const AddUser = () => import("../pages/AddUser.vue");
const Destinations = () => import("../pages/destinations/DestinationsPage.vue");
const BlogPage = () => import("../pages/blog/BlogPage.vue");
const InnerBlogPage = () => import("../pages/blog/InnerBlogPage.vue");
const SettingsPage = () => import("../pages/SettingsPage.vue");
const PrivacyTerms = () => import("../pages/PrivacyTerms.vue");
const PrivacyPage = () => import("../pages/PrivacyPage.vue");
const TermsPage = () => import("../pages/TermsPage.vue");
const IndexPage = () => import("../pages/IndexPage.vue");
const AboutPage = () => import("../pages/AboutPage.vue");
const InnerDestinations = () =>
  import("../pages/destinations/InnerDestination.vue");

const routes = [
  {
    path: "/",
    component: LoginLayout,
    children: [
      {
        path: "/",
        component: Login,
      },
      {
        path: "/new-pass",
        component: NewPass,
      },
      {
        path: "/confirm-pass/:token/:email",
        component: ConfirmPassword,
      },
    ],
  },
  {
    path: "/main",
    component: HeaderLayout,
    children: [
      {
        path: "/main",
        component: MainPage,
      },
      {
        path: "/roles",
        component: RolesPage,
      },
      {
        path: "/add-user/:id?",
        component: AddUser,
      },
      {
        path: "/destinations",
        component: Destinations,
      },
      {
        path: "/destination/:id?",
        component: InnerDestinations,
      },
      {
        path: "/blogs",
        component: BlogPage,
      },
      {
        path: "/blog/:id?",
        component: InnerBlogPage,
      },
      {
        path: "/settings",
        component: SettingsPage,
      },
      {
        path: "/privacy-terms",
        component: PrivacyTerms,
      },
      {
        path: "/privacy",
        component: PrivacyPage,
      },
      {
        path: "/terms",
        component: TermsPage,
      },
      {
        path: "/index",
        component: IndexPage,
      },
      {
        path: "/about",
        component: AboutPage,
      },
    ],
  },
];

// createWebHistory createWebHashHistory

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
