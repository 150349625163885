export default {
  closeModal(state) {
    state.modalActive = false;
  },
  showModal(state, info) {
    state.modalInfo = info;
    state.modalActive = true;
  },
  addNotification(state, modal) {
    state.popup.push(modal);
  },
  removePopup(state) {
    state.popup.splice(-1, 1);
  },
  updateKey(state) {
    state.reload = Date.now();
  },
};
