import appMutations from "./appMutations";

export default {
  state: {
    modalActive: false,
    modalInfo: {},
    popup: [],
    reload: Date.now(),
  },
  mutations: appMutations,
};
