/* eslint-disable no-empty */
import axios from "axios";

export default {
  async getDestinations({ state }) {
    try {
      const result = await axios.get(
        `${process.env.VUE_APP_ADMIN_URL}:${process.env.VUE_APP_ADMIN_PORT}/api/bestDeals/admin/getAll`
      );

      if (result.data?.responseCode?.responseCode !== "1") {
        throw new Error("");
      }

      state.destinations = result.data.data;
      state.destinationsIsLoaded = true;
    } catch {}
  },
};
