import { createStore } from "vuex";
import appState from "./app/appState";
import usersState from "./users/usersState";
import destinationsState from "./destinations/destinationsState";

export default createStore({
  modules: {
    app: appState,
    users: usersState,
    destinations: destinationsState,
  },
});
