<template>
  <div class="login-page">
    <logo-vue />
    <router-view />
  </div>
</template>

<script>
// Components
import LogoVue from "@/common/LogoVue.vue";

export default {
  components: {
    LogoVue,
  },
};
</script>

<style lang="scss" src="@/styles/login/layout.scss"></style>
