import destinationsMutations from "./destinationsMutations";
import destinationsActions from "./destinationsActions";

export default {
  state: {
    destinationsIsLoaded: false,
    destinations: {},
  },
  actions: destinationsActions,
  mutations: destinationsMutations,
};
