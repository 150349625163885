import axios from "axios";

export const authorization = async (email, password) => {
  try {
    const result = await axios.post(
      `${process.env.VUE_APP_ADMIN_URL}:${process.env.VUE_APP_ADMIN_PORT}/api/login`,
      { email, password }
    );

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${result.data.token}`;

    return [result.data, null];
  } catch (error) {
    return [null, error.message];
  }
};
