<template>
  <div class="login">
    <div class="title">Sign In</div>
    <input-vue
      name="email"
      label="email"
      placeholder="email@example.com"
      :value="email"
      @input-vue="email = $event"
    />
    <input-vue
      name="password"
      label="password"
      type="password"
      placeholder="Password"
      :value="password"
      @input-vue="password = $event"
    />
    <router-link to="/new-pass">Forgot Password?</router-link>
    <orange-filled-button :btn-text="'Sign In'" :click="proceedAuthorization" />
  </div>
</template>

<script>
// Components
import InputVue from "@/common/UI/InputVue.vue";
import OrangeFilledButton from "@/common/UI/OrangeFilledButton.vue";

// Controllers
import { authorization } from "@/controllers/authController";

export default {
  components: {
    InputVue,
    OrangeFilledButton,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async proceedAuthorization() {
      const [result, error] = await authorization(this.email, this.password);

      if (error !== null) {
        // Error
        return;
      }

      this.$store.commit("userInfo", result);
      this.$router.push("/main");
    },
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      this.email = "email@email.com";
      this.password = "12345678";
    }
  },
};
</script>

<style lang="scss" src="@/styles/login/login.scss"></style>
